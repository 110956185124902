



























import Vue from 'vue';

export default Vue.extend({
  name: 'InfoBlock',
  props: {
    label: String,
    value: String,
    secondaryValue: String,
    inline: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
});
